import React from 'react'
import './hinnasto2.css'
//import kuva2 from '../images/mainosPasuuna.jpg'
//import kuva from '../images/opistonKuva.JPG'
//import Muskari from '../images/muskariPikku.png'
//import Piano from '../images/pianokeys.jpg';
//import kissaOpisto from '../images/kissaOpisto.png';
import Helmet from 'react-helmet';

const Hinnasto = () => {


    return(
        
        <div className="">
        <Helmet>
          <title>Hinnasto -Kum -Keskisen Uuudenmaan Musiikkiopisto</title>
          <meta name="description" content="Hinnasto avoin musiikkiopisto , musiikkiopisto, muskari järvenpää / tuusula, soitinkokeilu, koulumusikantti "></meta>
          <link rel="canonical" href="http://kum.fi/hinnasto"></link>
        </Helmet>
        <div className="first-back select-color">
        <div class="css-1ymh205">
        <div className="yhteyis-hinnasto">
          <i className='fas fa-tags' style={{fontsize:'40px', marginTop: "0px"}}></i>
         </div> 
          <h2 style={{ textAlign: 'center', fontWeight: "300", fontSize: "40px" }}>HINNASTO JA MAKSUOHJEET</h2>
          <div class="css-1l4w6pd e8gdy8d0">
            <button id="perus" className="css-duea03" type="button"><a href='/hinnastoperusopetus'>Perusopetus</a></button>
            <button id="avoin" className="css-duea03 " type="button"><a href='/hinnastoavoin'>Avoin musiikkiopisto</a></button>
            <button id="muskari" className="css-duea03" type="button"><a href='/hinnastomuskari'>Muskari</a></button>
            <button id="muskari" className="css-duea03" type="button"><a href='/maksuohjeet' >Maksuohjeet</a></button>

            

          </div>
        </div>
        
          
          
        { /* <One /> */}
         
        
         </div>
        </div>

    )
}

export default Hinnasto;