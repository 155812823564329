

import React from 'react'
import './infoPage.css'
import NewsAndOther from './newsAndEvents'
import VideoContainer from './videoContainer'
import Descreption from '../components/litleDescription'

const infoPage = () => {


    const Lue = () => {
        window.location.href = "/opinnot";
        //console.log("opinnot")
    }


    return(
        <div>
        <VideoContainer />
        <section style={{ height:"100%"}} id="about">
			<h1>KUM</h1>
			<p>
            Tervetuloa opiskelemaan Keskisen Uudenmaan Musiikkiopistoon! 
            Olemme Järvenpään kaupungin ja Tuusulan kunnan yhteinen musiikkiopisto, ja meillä voi opiskella sekä klassista musiikkia että pop/jazzmusiikkia innostavien ja ammattitaitoisten opettajien johdolla.  
            Musiikkiopistomme tarjoaa mukavia hetkiä soittamisen ja laulamisen parissa, innostavia esiintymismahdollisuuksia ja myönteisiä tunne-elämyksiä.
            </p>
            <button onClick={Lue} className="btn-info">Lue lisää</button>

			<h2>Seuraa meitä myös</h2>

			<div className="social">
				<a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="balnk" ><i className="fa fa-facebook fa-3x"></i></a>
                <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank"><i className="fa fa-instagram fa-3x"></i></a>
                <a href="https://www.youtube.com/channel/UC2y2uuOWJqPspM7zHAK9Bvw" target="blank"><i className="fa fa-youtube fa-3x"></i></a>

			</div>
        </section>
       
       
        <NewsAndOther />
       
        <Descreption />
        </div>
    )
}

export default infoPage