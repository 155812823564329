import React, { useEffect, useState } from 'react';
//import im from '../images/trumpet.jpg';
//import im2 from '../images/pia.jpg';
import noImage from '../images/kumLogoLila.png';
import './newsAndEvents.css';
//import getEvents from '../services/events';
//import Zoom from 'react-reveal/Zoom';
import getFirstPage from '../services/firstPageBlogs';
import ReactMarkdown from 'react-markdown';
//import Axios from 'axios';
import Helmet from 'react-helmet';

import Joulu from '../components/joulu';

//const httpsKeikat = 'https://kumstrapi.xyz/testis'
//const httpKeikat =  'http://52.208.227.115/testis'

const News = () => {
  //const [ news, setNews ] = useState([]);
  const [ fewBlogs, setFewBlogs ] = useState([]);
  //const [ test , setTest ] = useState("")

  useEffect(() => {
    (async() => {
        //const res = await getEvents();
        const fewB = await getFirstPage();
        //console.log(fewB.sort((a, b) => b.id - a.id));
        
        //const keikat = await Axios.get('http://52.208.227.115/testis');
        //const keikat = await Axios.get(httpsKeikat);

        
        
        //setTest(keikat.data.map(x => x.info))
        //console.log(keikat.data.map(x => x.info))
        
        setFewBlogs(fewB.sort((a, b) => b.id - a.id));
        //setNews(res);
    })()
  }, [])


    const goTo = () => {
      window.location.href = "/ajankohtaiset/firstpage"
    }
    const oneBlog = (id) => {
        console.log(id);
        window.location.href = `/ajankohtaiset/${id}`
    }
    const FewBlog = () => {
      if (fewBlogs.length === 0){
        return(
          <div>
             <h4>Loading...</h4>
          </div>
        )
      } else {
       
        return(
          <div className='backNews'>
          <hr className="container" style={{marginTop:"10px"}}></hr>

          <h3 className="vali-otsikko">Ajankohtaista</h3>

          <div className="onclick-image container" >
              {fewBlogs.map(x => 
               
                <div style={{ boxShadow: "0 6px 10px rgba(0,0,0,0.1)"}} className="item photo" onClick={() => oneBlog(x.Otsikko)} key={x.id}>
                   
                   {x.blogiKuva['url'] !== null  ? 
                    <img className="photothumb" alt={x.Otsikko + "Kum / Keskisen Uuudenmaan Musiikkiopisto"} src={`https://kumstrapi.xyz${x.blogiKuva['url']}`}/>
                    : <img src={noImage} alt="Keskisen Uudenmann Musiikkiopisto Kum Järvenpää Tuusula"></img>
                   }
                   <div style={{ padding: "15px"}}>
                      <div className="title2">
                          <h3>{x.Otsikko}</h3>
                      </div>
                      <div className="desc">
                        <ReactMarkdown
                        children={x.lyhytKuvaus}
                      />  
                  </div>
                  </div>
                </div>
                
              )}


          </div>
           <br></br>
           <div style={{ textAlgin: "center"}} className="readMore">
             <button onClick={goTo} className="btn1"><span>LUE LISÄÄ AJANKOHTAISIA</span></button>
           </div>
          
          </div>
        )
      }
    }
    
    return(
       <div className="news-event">
       <Helmet>
         <meta name="description" content="Tervetuloa opiskelemaan Keskisen Uudenmaan Musiikkiopistoon! Olemme Järvenpään kaupungin ja Tuusulan kunnan yhteinen musiikkiopisto, ja meillä voi opiskella sekä klassista musiikkia että pop/jazzmusiikkia innostavien ja ammattitaitoisten opettajien johdolla. Musiikkiopistomme tarjoaa mukavia hetkiä soittamisen ja laulamisen parissa.
          Kesksien Uudenmaan Musiikkiopiston ajankohtaiset uutiset tapahtumat henkilöt sekä tulevat konsertiti.
          Muskari eli musiikkileikkikoulu tarjoaa lapselle oman ihanan harrastuksen, jossa lapsi viihtyy, oppii ja saa elämyksiä.
          "></meta>     
       </Helmet>
        <div className="grid">
         
         
          <FewBlog />
          {/*
          <div class="item photo">
             <div class="title">
              <h3>Uusien oppilaiden ilmoittautuminen</h3>
            </div>
            <img class="photothumb" alt="im3" src={im3} />

            <div class="desc">
               <p>Uusien oppilaiden ilmoittautuminen alkaa lauantaina 18.4. klo 12 osoitteessa kum.eepos.fi. Tervetuloa musisoimaan!</p>
            </div>
           </div>
           <div class="item photo">
              <div class="title">
                <h3>Musiikkiopiston tiedote</h3>
             </div>
             <img class="photothumb" alt="im1" src={im} />
              <div class="desc">
               <p>Koronatilanteen sekä valtioneuvoston ohjeiden johdosta musiikkiopiston lähiopetus on keskeytetty toistaiseksi. Poikkeustilan aikana musiikkiopiston opetus järjestetään etäopetuksena.</p>
             </div>
             </div>
          <div class="item photo">
            <div class="title">
              <h3>Jatkoilmoittautuminen käynnissä!</h3>
            </div>
              <img class="photothumb" alt="im2" src={im2} />
            <div class="desc">
              <p>Ilmoittaudu mukaan myös lukuvuodelle 2020-2021!</p>
            </div>
           </div>
          */}
          <br></br>
         
          

          </div>
         
            <Joulu />



          
        
         
          </div>

      
         
        
        

    )
}

export default News;