
import React, { useEffect, useState } from 'react';
//import './opinnot.css';
import Axios from 'axios'
import ReactMarkdown from 'react-markdown';
import './konsertit.css'
//import Kuva from '../images/demo.jpg'

const httpsKeikat = 'https://kumstrapi.xyz/testis'
//const httpKeikat =  'http://52.208.227.115/testis'


const Konsertit = () => {
    const [ test , setTest ] = useState("")
      
    useEffect(() => {
          (async() => {
              //const keikat = await Axios.get('http://52.208.227.115/testis');
              const keikat = await Axios.get(httpsKeikat);
              setTest(keikat.data.map(x => x.info))
              //console.log(keikat.data.map(x => x.info))
              
          })()
          
        }, [])
      

    

    const ShowText = () => {
        
        if (test[0] != null){
        return(
            <div className='main-konsertti'>
            <div className='konsertit-main'>
                 <h2 className="container" style={{textAlign: "center", marginTop: "50px", marginBottom: "20px", fontWeight: "300", textDecoration:"none", fontSize: "50px"}}>Tulevat konsertit</h2>     

            </div>
            <div className='konsertit container'>
            <div  className="style-mark">    
           
                <ReactMarkdown className='mark' children={test[0]} /> 
            
            </div>
            </div>
            </div>
            
            
        )
        } else {
        //console.log(viikonLoppu[0].info)
        return(
            <div style={{ height: "80vh"}}>
                 <p>Kursseja ladataan</p>
            </div>
           
        )
        }
    }


    return(
        <div>
            <div className="">
                    <ShowText />   
            </div>
         
        </div>
    )
}

export default Konsertit;