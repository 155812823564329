import React from 'react'
import './hinnasto2.css'
import NavHinnasto from './hinnasto2'


const hinnastoAvoin = () => {



    return (

        <div className="avoin-opetus">
        <NavHinnasto />
        <div className="container">
                
            <div className="title-total">   
                    <h2>Avoimen opetuksen hinnasto</h2>
                    <br></br>
                
                    <p style={{ fontSize: "15px", width: "100%"}}>Avoimeen musiikkiopistoon on jatkuva ilmoittautuminen nettisivuillamme. Oppilaat otetaan vapautuville oppilaspaikoille ilmoittautumisjärjestyksessä. Otamme yhteyttä, kun paikka vapautuu. Opetukseen ei ole ikä- tai kotikuntarajoituksia.</p>
                    <br></br>
                    <i class='fas fa-tags' style={{fontsize:'24px'}}></i>

                    <table className="card-hinnasto">
                    <tbody>
                    <tr>
                        <th>AVOIN YKSILÖOPETUS</th>
                        <th>10 KRT / LUKUKAUSI</th>
                        <th>15 KRT / LUKUKAUSI</th>
                    </tr>
                    <tr>
                        <td>20 min</td>
                        <td>231€</td>
                        <td>345€</td>
                    </tr>
                    <tr>
                        <td>30 min</td>
                        <td>345€</td>
                        <td>460€</td>
                    
                    </tr>
                    <tr>
                        <td>45 min</td>
                        <td>460€</td>
                        <td>643€</td>
                    
                    </tr>
                    <tr>
                        <td>60 min</td>
                        <td>572€</td>
                        <td>857€</td>
                    
                    </tr>
                    </tbody>
                    </table> 
            </div>
            <br></br>
            <i class='fas fa-tags' style={{fontsize:'24px'}}></i>
        
                    <table className="card-hinnasto">
                            <tbody>
                            <tr className=""><td>Aikuisten viikonloppukurssit</td>
                            <td><strong>40€ / 80€ / kurssi </strong></td></tr>
                            <tr className=""><td>Koulumusikantit / musikantit</td>
                            <td><strong>135€ / lukukausi </strong></td></tr>
                            <tr className=""><td>Kuoro / orkesteri ainoana opintona*</td>
                            <td><strong>75 € / lukukausi</strong></td></tr>
                            <tr className=""><td>Musiikin hahmotusaineet ainoana opintona*</td>
                            <td><strong>75 € /lukukausi</strong></td></tr>
                            <tr className=""><td>Soitinkokeilu</td>
                            <td><strong>115,50 €</strong></td></tr>
                            <tr className=""><td>Soitinkokeilu pariopetuksena (HUOM! Pari mainittava ilmoittautuessa)</td>
                            <td><strong>84 € / hlö</strong></td></tr>
                            <tr className=""><td>Soitinvuokra</td>
                            <td><strong> 50 € / lukukausi</strong></td></tr>
                            <tr className=""><td>Soitinvuokra koulumusikantit / musikantit</td>
                            <td><strong>15 € / lukukausi</strong></td></tr>
                            <tr className=""><td>Soitinvuokra soitinkokeilun ajalta</td>
                            <td><strong>15 €</strong></td></tr>
                            </tbody>           
                    </table> 
            <br></br>
            <p style={{ fontSize: "15px"}}><strong>*Avoimen musiikkiopiston instrumenttioppilaille</strong> osallistuminen ryhmäopetukseen (musiikkileikkikoulu, musiikkivalmennus, kuoro, orkesterit ja musiikin hahmotusaineet) sisältyy lukukausimaksuun, eikä niistä veloiteta erikseen.</p> 
            <br></br>
            <p style={{ fontSize: "15px"}}><strong>*Musikantit ja koulumusikantit</strong> voivat osallistua muskari- ja musiikkivalmennusryhmiin puoleen hintaan.  Kuoroon, orkestereihin ja musiikin hahmotusaineiden ryhmiin osallistuminen on maksutonta.</p>
            <br></br>
            <p><strong>HUOM! Opinnot jatkuvat syyslukukaudelta kevätlukukaudelle automaattisesti ilman erillistä ilmoittautumista.</strong></p>
                

            <br></br>
                <div className="" >
                    <div className="">
                        <h4>Opintojen lopettaminen</h4>
                        <p style={{ fontSize: "15px"}}>Täysi-ikäinen oppilas/huoltaja ilmoittaa opintojen lopettamisesta sähköpostitse musiikkiopiston toimistoon musiikkiopisto@jarvenpaa.fi.</p>
                        <p style={{ fontSize: "15px"}}>Opinnot katsotaan loppuneeksi sähköpostin lähetyspäivänä.</p>
                        <br></br>
                        <h4>Avoin yksilöopetus</h4>
                        <p style={{ fontSize: "15px"}}>Jos opinnot lopettaa ennen lukukausimaksujen lähettämistä, maksetaan vain pidetyistä tunneista. Opinnot katsotaan loppuneeksi sinä päivänä, kun huoltaja tai täysi- ikäinen oppilas lähettää sähköpostin musiikkiopiston toimistoon. Mikäli lukukausimaksun lasku on jo lähetetty,<strong> laskua ei hyvitetä.</strong> Huomioithan, että myös oppilaan poissaolokerta on pidetty opetuskerta.</p>
                        <br></br>
                        <h4>Koulumusikantit ja musikantit</h4>
                        <p style={{ fontSize: "15px"}}>Koulumusikantit ja musikantit laskutetaan jaksoissa. Yksi jakso sisältää 10 opetuskertaa. Mikäli harrastus lopetetaan kesken jakson, veloitetaan koko jaksomaksu voimassa olevan hinnaston mukaan. Huomioithan, että myös oppilaan poissaolokerta on pidetty opetuskerta.</p>
                        <br></br>
                        <h4>Viikonloppukurssit</h4>
                        <p style={{ fontSize: "15px"}}>Viikonloppukurssien ilmoittautuminen on sitova, peruutus tulee tehdä viimeistään vuorokautta ennen kurssin aloitusta. Kurssimaksu veloitetaan täysimääräisenä, mikäli peruutus tehdään alle vuorokautta ennen kurssin alkua tai kurssille ei ilmaannuta ollenkaan.</p>
                    </div>
                </div>
             <br></br>

            {/*
            <div className="portada">
                <img style={{ height: "400px"}} src={kuva2} alt="hinnasto-kuva"></img>
            </div>
            */}
            </div>
      </div>

     
    )
}

export default hinnastoAvoin;