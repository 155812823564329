import React from 'react';
import './opettajat.css';
import Opet from '../images/opettajat.jpg';
import opetJson from '../opettajat/opet.json'

const Opettajat = () => {
  /*
    const convertToEmailAddress = (name) => {

      const [firstName, lastName] = name.split(' ');
  

      const emailAddress = `${lastName.toLowerCase()}.${firstName.toLowerCase()}@jarvenpaa.fi`;
  
      return emailAddress;
    }

    */
    return(
        <div style={{ marginTop: "120px", textAlign: "center" }} className="container">
          <img style={{ width: "100%", borderRadius: "20px", marginTop: "10px", maxHeight: "800px" }} src={Opet} alt="Kum-opettajat"></img>
          <p>
          Sähköpostiosoitteet ovat muotoa etunimi.sukunimi@jarvenpaa.fi
          </p>
          {/*
          <div  className="opettajat" style={{ textAlign: "left"}}><p>
          &nbsp;
          </p>
          <p>
          Sähköpostiosoitteet ovat muotoa etunimi.sukunimi@jarvenpaa.fi
          </p>
          <p>
          &nbsp;
          </p>
          <p>
          <strong>Aaltonen Rikupetteri</strong>, trumpetti, puhallinorkesterit
          </p>
          <p>
          <strong>Ahlajoki Juhani</strong>, sähköbasso
          </p>
          <p>
          <strong>Ahvenainen-Saaristo Heli</strong>,&nbsp;piano, säestys
          </p>
          <p>
          <strong>Airola Kari</strong>, rummut
          </p>
         
          <p>
          <strong>Antikainen Sanni</strong>, piano, cembalo
          </p>
          <p>
          <strong>Arpalahti Tuire</strong>, viulu
          </p>
         
          <p>
          <strong>Ceker Tiina</strong>, oboe
          </p>
        
          <p>
          <strong>Grice Kristina</strong>, koulumusikantit, piano
          </p>
          <p>
          <strong>Ellenberg-Haapaniemi Johanna</strong>, viulu
          </p>
          <p>
          <strong>Haikonen Jukka</strong>, musiikin hahmotusaineet
          </p>
          <p>
          <strong>Haikonen Kaisa</strong>, musiikkivalmennus, kuorot, koulumusikantit: piano
          </p>
         
          <p>
          <strong>Helin Jarkko</strong>, kitara
          </p>
          <p>
          <strong>Higurashi-Kekola Ayako</strong>, pasuuna, tuuba, baritoni- & tenoritorvi
          </p>
          <p>
          <strong>Hirvonen Kristiina</strong>, sello
          </p>
          <p>
          <strong>Hovi Taina</strong>, laulu, nuorisokuoro
          </p>
        
        
          <p>
          <strong>Hyyrynen Olli</strong>, kitara
          </p>
         
          <p>
          <strong>Jukkola Marja</strong>, musiikin hahmotusaineet, viulu,&nbsp;alttoviulu
          </p>
          <p>
          <strong>Kaarisola-Kulo Satu</strong>, laulu, nuorisokuoro
          </p>
          <p>
          <strong>Kari Mia</strong>, käyrätorvi
          </p>
          <p>
          <strong>Kattainen Anna</strong>, kantele
          </p>
          <p>
          <strong>Kerppo Raija</strong>, piano
          </p>
          <p>
          <strong>Kimpimäki Leena</strong>, viulu
          </p>
         
          <p>
          <strong>Korte Katriina</strong>, piano
          </p>
          <p>
          <strong>Kouvo Elsi</strong>, klarinetti,koulumusikantit: piano
          </p>
          
          <p>
          <strong>Kulo Heikki</strong>,&nbsp;sello, nuorten jousiorkesteri
          </p>
          
          <p>
          <strong>Könönen Pasi</strong>, sähkökitara
          </p>
          <p>
          <strong>Lehto Taina</strong>, pop/jazz-laulu
          </p>
          <p>
          <strong>Lehtonen Camilla</strong>, musiikkileikkikoulu
          </p>
          <p>
          <strong>Lydecken Marja</strong>, huilu
          </p>
          <p>
          <strong>Maijala Paavo</strong>, piano
          </p>
          <p>
          <strong>Martin Leila</strong>, lyömäsoittimet
          </p>
        
          <p>
           <strong>Mustaniemi Hanna</strong>, musiikin hahmotusaineet
          </p>

         
          <p>
          <strong>Nygren Joonas</strong>, sähkökitara, bändit
         </p>
          <p>
           <strong>Ojapelto Johanna</strong>, huilu
          </p>
          <p>
           <strong>Ovaska Johanna</strong>, viulu
          </p>
          <p>
           <strong>Paunonen Karoliina</strong>, harmonikka
          </p>

          <p>
          <strong>Pekkarinen Outi</strong>, musiikkileikkikoulu, lauluvalmennus, laulu
          </p>

          <p>
          <strong>Peltoniemi Jenni</strong>,  saksofoni
          </p>
         
        
          <p>
          <strong>Purhonen Eero</strong>, fagotti
          </p>
          <p>
          <strong>Putkonen Juha-Pekka</strong>, kitara, apulaisrehtori
          </p>
          <p>
          <strong>Rautio-Härkönen Heta</strong>, koulumusikantit: piano
          </p>
          <p>
          <strong>Raijas Pirre</strong>, rehtori
          </p>
         
          <p>
          <strong>Rautio-Härkönen Heta</strong>, koulumusikantit: piano
          </p>
          <p>
          <strong>Salo Juulia</strong>, kantele
          </p>
          <p>
          <strong>Silvasti Anu</strong>, piano
          </p>
          <p>
          <strong>Sinkkonen Risto</strong>, pop/jazz-piano
          </p>
          <p>
          <strong>Sorjonen Alina</strong>, piano
          </p>
          <p>
          <strong>Tervala Paula</strong>, saksofoni
          </p>
          <p>
          <strong>Tiihonen Henri</strong>, piano
          </p>
          <p>
          <strong>Uusisilta Anssi</strong>,  koulumusikantit: bändi
          </p>
         
          <p>
          <strong>Uusitalo Mari</strong>, huilu
          </p>
          
          <p>
          <strong>Viitanen Janne</strong>, kontrabasso, koulumusikantit: piano
          </p>
          <p>
          &nbsp;
          </p></div>
          */}

          <table className='opeTable'> 
            <tr>
              <th>#</th>
              <th></th>
              <th></th>
            </tr>
              {opetJson.map((x, y) =>
                <tr>
                  <td style={{fontWeight: "bold"}}>{x.name}</td>
                  <td>{x.data}</td>
                </tr>
              )}

          </table>
          
        </div>
    )
}

export default Opettajat;