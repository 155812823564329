import axios from 'axios';

const httpUrl = 'http://52.208.227.115/blogs'
const httpsUrl = "https://kumstrapi.xyz/blogs"

const getBlogsTrue = async () => {
    //const res = await axios.get('http://52.208.227.115/blogs')
    const res = await axios.get(httpsUrl)

    return res.data.filter(x => x.etuSivulle === 1);
}

export default getBlogsTrue