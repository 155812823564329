import React , { useState, useEffect } from 'react'
import './videoContainer.css'
//import Vid1 from '../videos/KumNoAudioz.mp4'
import KumMobile from '../videos/Kum3M.mov';
//import KumMobile from '../videos/Kum2Testi.mov'
import KumDesktop from '../videos/Kum3Desktop.mp4';
//import tes from '../videos/nim.png'
//import Slider from './mobileTest';
//import InfoBanner from '../components/infobanner';


const videoContainer = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ screenSize , setScreenSize ] = useState(700)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setScreenSize(window.innerWidth);

  }, [])

  const Ilmottaudu = () => {
    window.open(
      'https://kum.eepos.fi/#/splash',
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  const Mobile =  () => {
    console.log(screenSize)
    if (screenSize < 600){
      return(
        <section className="showcase">
      
       
        <div className="video-container small-mobile">
        {
        <video autoPlay loop muted playsInline>
           <source src={KumMobile} type="video/mp4"/>
        </video>
        }
        </div>
          
          <div className="video-content">
            <h1>Keskisen Uudenmaan Musiikkiopisto </h1>
         {/* <h2 style={{ fontWeight: "200", fontSize: "27px"}} >Ilmoittautuminen syksyn opintoihin alkaa la 17.4. klo 9.00</h2> /*}
            {/* <p>Meidän musiikkiopisto</p> */}
            <button className="btn" onClick={Ilmottaudu} >Ilmoittaudu mukaan</button>
          </div>
        
        </section>
      )
      
    } else if (screenSize > 600) {
      return(
        <section className="showcase">
       
        <div className="video-container">
        {
        <video autoPlay loop muted playsInline>
           <source src={KumDesktop} type="video/mp4"/>
        </video>
        }
        </div>
          
          <div className="video-content">
            <h1>Keskisen Uudenmaan Musiikkiopisto </h1>
           {/* <h2 style={{fontWeight: 200, fontSize:"35px" }}>Ilmoittautuminen syksyn opintoihin alkaa la 17.4. klo 9.00</h2>*/}

            {/* <p>Meidän musiikkiopisto</p> */}
            <button className="btn" onClick={Ilmottaudu} >Ilmoittaudu mukaan</button>
          </div>
        
        </section>
      )
    } else {
      return (
        null
      )
    }
  }
  
   

    return(
        <div>
         
          <Mobile />
         {/* <InfoBanner />  */}
        </div>
    )

}

export default videoContainer;