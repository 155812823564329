import React from 'react'
import './nav2.css'
import logo from '../images/logoNav.png'
//import logoMobile from '../images/koulumusikanttiRotate.png';

class navBar extends React.Component { 
 constructor(props){
  super(props)
  this.state = {
    posY: 0,
    posX: 0,
    oldValue: 0,
    show: "block",
    burgerOpen: false,
    increment: 0

  }
}

componentDidMount(){
  window.addEventListener("scroll", this.listenScroll)
}
componentWillMount(){
  window.removeEventListener("scroll", this.listenScroll)
}

listenScroll = () => {
  let newValue = window.pageYOffset


  if(this.state.oldValue - newValue < -50 && this.state.increment % 2 === 0){
     document.getElementById('oma-nav').style.display = "none"
     


  } else if(this.state.oldValue - newValue > 0 && this.state.increment % 2 === 0){
 
    document.getElementById('oma-nav').style.backgroundColor = "white"
    document.getElementById('oma-nav').style.display = "flex"
    document.getElementById('oma-nav').style.animation = "mymove 0.3s"

   
    
  } 

  this.setState({ oldValue: newValue })
 
}
  
 render() {
   
 
    const navSlide = () => {
        this.setState({ increment : this.state.increment + 1 })
        const nav = document.querySelector('.nav-links')
        nav.classList.toggle('nav-active');
      
      //  nav.classList.toggle('oma-testi')

       // document.getElementById("what").style.display = "block"

        // Burger Animate //
        const burger = document.querySelector('.burger')
        burger.classList.toggle('toggle')

        if (this.state.increment % 2 === 0){
          document.getElementById('social-id').style.display = "block"

        } else {
          setTimeout(() => {
            document.getElementById('social-id').style.display = "none"

          }, 1000)
        }



       

    }
    const Paluu = () => {
     // console.log("Paluu")
      window.location.href = "/"
    }
    const muskari = () => {
      window.location.href = "/muskari"
    }
    /*
    const ajankohtaista = () => {
      window.location.href = "/toimipisteet"

    }
    */
    const fag = () => {
      window.location.href = "/faq"
    }

    const ilmoittaudu = () => {
      //window.location.href = "https://kum.eepos.fi/#/splash"
      window.open(
        'https://kum.eepos.fi/#/splash',
        '_blank' // <- This is what makes it open in a new window.
      );
    }
    const opinnot = () => {
      document.getElementById("opinnot-content").classList.toggle("show");
      document.getElementById("opinnot-button").classList.toggle("under")
      //console.log(document.getElementsByClassName('dropdown-content'))
      
      document.getElementById("info-content").classList.remove("show")
      document.getElementById("info-button").classList.remove("under")

      document.getElementById("yhteys-content").classList.remove("show");
      document.getElementById("yhteys-button").classList.remove("under")

      document.getElementById("ajan-content").classList.remove("show")
      document.getElementById("ajan-button").classList.remove("under")
      

    }
    const Yhteystiedot = () => {
      document.getElementById("yhteys-content").classList.toggle("show");
      document.getElementById("yhteys-button").classList.toggle("under")
      //console.log(document.getElementsByClassName('dropdown-content'))
      
      document.getElementById("info-content").classList.remove("show")
      document.getElementById("info-button").classList.remove("under")

      document.getElementById("opinnot-content").classList.remove("show");
      document.getElementById("opinnot-button").classList.remove("under")
      
      document.getElementById("ajan-content").classList.remove("show")
      document.getElementById("ajan-button").classList.remove("under")
      
    }
    const info = () => {
      document.getElementById("info-content").classList.toggle("show")
      document.getElementById("info-button").classList.toggle("under")
      
      document.getElementById("opinnot-content").classList.remove("show");
      document.getElementById("opinnot-button").classList.remove("under")

      document.getElementById("yhteys-content").classList.remove("show");
      document.getElementById("yhteys-button").classList.remove("under")

      document.getElementById("ajan-content").classList.remove("show")
      document.getElementById("ajan-button").classList.remove("under")
    }
    const ajan = () => {
      document.getElementById("ajan-content").classList.toggle("show")
      document.getElementById("ajan-button").classList.toggle("under")

      document.getElementById("info-content").classList.remove("show")
      document.getElementById("info-button").classList.remove("under")
      
      document.getElementById("opinnot-content").classList.remove("show");
      document.getElementById("opinnot-button").classList.remove("under")

      document.getElementById("yhteys-content").classList.remove("show");
      document.getElementById("yhteys-button").classList.remove("under")

    
      
      window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
          var dropdowns = document.getElementsByClassName("dropdown-content");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
              openDropdown.classList.remove('show');
            }
          }
        }
      }

    }
    
    return(
       <div className='main-nav' id="oma-nav">
       <div className='nav-upper'>
         
          <div className="upper-logo">
            <h3 onClick={Paluu}>KUM </h3>
            <img src={logo} alt="logo"></img>
          </div>

          <div className="upper-social" id="upper-s">
                        <a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="blank"><i className="fa fa-facebook fa-3x"></i></a>
                        <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank"><i className="fa fa-instagram fa-3x"></i></a>
                        <a href="https://www.youtube.com/channel/UCnPoZNsDmJ0-bibMtaIvOmw?view_as=subscriber" target="blank"><i className="fa fa-youtube fa-3x"></i></a>
                      
          </div>

          <div  onClick={navSlide} className="burger">
             <div className="line1"></div>
             <div className="line2"></div>
             <div className="line3"></div>          
          </div>

       </div>
        <div className="own-nav">
          
          
          <ul id="what" className="nav-links">
            
            

             <li className="dropdown">
               <button onClick={opinnot} id="opinnot-button" className="dropbtn">Musiikin opiskelu <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
               <div id="opinnot-content" className="dropdown-content">

                 <a href="/opiskelu">Tietoa opiskelusta</a>
                 <a href="/oppilaalle">Oppilaalle</a>
                 <a href="/viikonloppukurssit">Viikonloppukurssit</a>
                
                {/*
                 <a href="/opinnot">Opintopolut</a>
                 <a href="/opinnot/taiteenperusopetus">Taiteen perusopetus</a>
                 <a href="/opinnot/popjazz">Pop/jazz-osasto</a>
                 <a href="/opinnot/yhteismusisointi">Yhteismusisointi</a>
                 <a href="/opinnot/hahmotusaineet">Teoria-aineet</a>
                 <a href="/opinnot/avoinmusiikkiopisto">Avoin musiikkiopisto</a>                
                 <a href="/opinnot/koulumusikantti">Koulumusikantit</a>
                 <a href="/opinnot/viikonloppukurssit">Viikonloppukurssit</a>
                 <a href="/opinnot/soitinkokeilu">Soitinkokeilu</a>
                */}
                 
                 </div>
                
             
             </li>
          
              <li className="dropdown">
                <button onClick={muskari}  className="dropbtn">Muskari </button>
             </li> 
             <li className="dropdown">
                 <button  onClick={ajan} id="ajan-button" className="dropbtn">Ajankohtaista <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
                    <div id="ajan-content" className="dropdown-content">
                      <a href="/ajankohtaiset/firstpage">Tiedotteet</a>
                      <a href="/konsertit">Tapahtumat</a>
                    </div>
                  </li> 

               <li className="dropdown">
                 <button onClick={info} id="info-button" className="dropbtn">Info <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
                <div id="info-content" className="dropdown-content">
                   <a href='/info'>Infoa Musiikkiopistosta</a>
                   {/* <a href="/toimipisteet/?opettaja=opettajat">Opettajat</a> */}
                   <a href="/hinnastoperusopetus">Hinnasto</a>
                  {/* <a href="/soittimet">Soitinesittely</a> */}
                   {/* <a href="/toimipisteet">Yhteystiedot</a> */}
                   <a href="/esiintyjävälitys">Esiintyjävälitys</a>
                   <a href="https://kum.eepos.fi/#/splash">Eepos</a>
                  
                   
                   {/*<a href="/faq">FAQ</a>*/}
                </div>
               </li> 
                  <li  className="dropdown">
                    <button onClick={fag} className="dropbtn">FAQ</button>
            
                  </li>
                <li className="dropdown">
                 <button  onClick={Yhteystiedot} id="yhteys-button" className="dropbtn">Yhteystiedot <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
                    <div id="yhteys-content" className="dropdown-content">
                      <a href='/opettajat' >Opettajat</a>
                      <a href="/toimipisteet">Yhteystiedot</a>
                      <a href="/laskutustiedot">Laskutustiedot</a>
                     
                    </div>
                  </li> 

                  <li  className="dropdown">
                    <button onClick={ilmoittaudu} className="dropbtn">Ilmoittaudu</button>
                  </li>
                  
                
                <div className="social2" id="social-id" style={{marginTop: "10px"}}>
                      <a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" target="blank"><i className="fa fa-facebook fa-3x"></i></a>
                      <a href="https://www.instagram.com/musiikkiopisto_kum/" target="blank"><i className="fa fa-instagram fa-3x"></i></a>
                      <a href="https://www.youtube.com/channel/UCnPoZNsDmJ0-bibMtaIvOmw?view_as=subscriber" target="blank"><i className="fa fa-youtube fa-3x"></i></a>
                     
                </div>


              </ul>
              
        
       

        </div>
        </div>
        
       
    )
}
}
export default navBar;