/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './laskutus.css';
import Kuva from '../images/logo.png';

const Laskutus = () => {



    return(
        <div className="laskutus">
          <div className="lasku-img">
          <br></br>
             <img src={Kuva} style={{width:"100%"}} alt="laskutus-image" ></img>   
         
          </div>
          <div className="laskutus-text">
           <h2>Laskutustiedot</h2>
           <p><strong>Verkkolasku:</strong></p> 
           <p>Järvenpään kaupunki</p>
           <p>Verkkolaskuosoite/OVT: 003701265414</p>
           <p>Välittäjä: 003703575029</p>
           <p>Operaattori: Telia</p>
           
           <br></br>
           <p>Y-tunnus: 0126541-4</p>
           <p>TILAUSVIITE: 1865011002</p>
           <p>Tilaajan nimi</p>
           <br></br>
           {/*
            <p><strong>Jos verkkolaskutus ei ole mahdollista, paperilaskujen osoite:</strong></p>
            <p>Järvenpään kaupunki 003701265414</p>
            <p>PL 299</p>
            <p>02066 DOCUSCAN</p>
           */}
          </div>
        </div>
      
    )
}


export default Laskutus;
