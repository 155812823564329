import React from 'react'
import './muskari.css'
//import Kuva from '../images/koulumusikantti.png'
import Vid from '../instrumentsVideo/muskari.mp4'
//import Flip from 'react-reveal/Fade'
import Helmet from 'react-helmet';
import musiikkivalmennus from '../images/musiikkivalmennus.png'
import lauluvalmennus from '../images/lauluvalmennus.png'
import viisi from '../images/5.png'
import kolme from '../images/3-4.png'
//import eka from '../images/muskari.png'
import muskariRyhma from '../images/muskariRyhma.jpg'
import nollaKaksi from '../images/mus02-2.png'
//import kuva2 from '../images/muskariPikku.png'
//import ekaKuva from '../images/muskari2022.jpg'


const Muskari = () => {

 
    return(
      <div className="muskari-back">
       <Helmet>
          <title>KUM Muskari</title>
          <meta name="dexription" content="Olemme Järvenpään kaupungin ja Tuusulan kunnan yhteinen musiikkiopisto. Muskari eli musiikkileikkikoulu tarjoaa lapselle oman ihanan harrastuksen, jossa lapsi viihtyy, oppii ja saa elämyksiä."></meta>
          <link rel="canonical" href="http://kum.fi/muskari"></link>
       </Helmet>
        <div className="container" >
      
          <div className="wrapper clearfix">
            <div style={{"textAlign": "center", paddingBottom: "20px", paddingTop: "20px"}}>
             <h1>Muskari</h1>
            </div>

            <div className="circle"></div>
          {/*
          <Bounce>
           <div className="circle"></div>
          </Bounce>
          */} 
         {/* <Modal /> */}
            {/*<h1>Muskari</h1> */}
            
              
              <div className='circle-text' style={{ marginTop: "20px"}}>
              <h2>Tarjoa lapselle musiikin maailma!</h2>
              <br></br>
             

              <p>Muskari eli musiikkileikkikoulu tarjoaa lapselle oman ihanan harrastuksen, jossa lapsi viihtyy, oppii ja saa elämyksiä.</p>
              <p> Musiikkikasvatuksella on tutkitusti tärkeä ja merkittävä osa lapsen kielellisen, motorisen, emotionaalisen ja sosiaalisen kehityksen tukemisessa.</p>
              <br></br>
              <p> Opetuksen tavoitteena on kehittää lapsen laulu- ja soittotaitoa, rytmi- ja melodiatajua, kuuntelukykyä sekä musiikillista ilmaisua. </p>
              <br></br>
              <p>Musiikkiopiston muskariopettajat ovat musiikkikasvatuksen ammattilaisia.</p>
              <br></br>
              </div>
             

          </div>
       
                   
                  
                    <div  className="muskari">
                    <div className="muskari-card">   
                          <img src={muskariRyhma} alt="kum lauluvalmennus muskarit" width="90%"></img>
                          <div className='muskari-card-text'>
          
                                <h3>Muskariryhmät</h3>
                                <p style={{marginTop: "10px"}}><strong>Tutustu lukuvuoden 2024—2025 ryhmiin <span><a href="https://docs.google.com/file/d/1dXu0oLEhPeWxE3nLVgcNCV5LY0NvO9Ki/edit?usp=docslist_api&filetype=msexcel" target="blank">TÄSTÄ</a></span></strong></p>
              
                                  <p style={{marginTop: "10px"}}><strong>Muskariin ja lauluvalmennukseen pääset ilmoittautumaan <span><a href="https://kum.eepos.fi/#/forms" target="blank"> TÄSTÄ</a></span></strong></p>
                                  <p><strong>Tutustu muskarin hinnastoon</strong> <strong><a href="/hinnastomuskari">TÄSTÄ</a></strong></p>

                                  <p>Tervetuloa tutustumaan muskariin <strong>ensimmäisen kerran maksutta!</strong></p>

                          </div>
                         
                      </div>

                      <div className="muskari-card">          
                          <img src={nollaKaksi} width='90%' alt="kum perheryhmät muskari"></img>
                          <div className='muskari-card-text'>
                            <h3>0–2-vuotiaiden perheryhmät</h3>
                            <p>Vauvojen ja taaperoiden perheryhmissä vanhempi ja lapsi nauttivat yhdessä musiikin riemusta. 
                              Ryhmissä körötellään, leikitään, lauletaan, soitetaan ja kuunnellaan musiikkia sekä liikutaan musiikin tahdissa. Tunnit suunnitellaan lapsen ikätason mukaan, jolloin muskari tukee lapsen kokonaisvaltaista kehitystä ja tarjoaa iloa ja elämyksiä. Ryhmä kokoontuu kerran viikossa 45 minuutin tunnille, 
                            jolle tullaan oman aikuisen kanssa.</p>
                          </div>
                      </div>
                      
                      <div className="muskari-card">   
                          <img src={kolme} alt="kum musiikkileikkiryhmät 3-4 vuotiaat" width="90%"></img>
                          <div className='muskari-card-text'>
                            <h3>3–4-vuotiaiden musiikkileikkiryhmät</h3>
                            <p>Musiikkileikkiryhmissä lapsi tutustuu musiikkiin 	elämyksellisesti ja leikinomaisesti mm. laulaen, loruillen, soittaen, liikkuen, kuunnellen ja leikkien.
                                Tunnit suunnitellaan lapsen ikätason mukaan, jolloin muskari tukee lapsen kokonaisvaltaista kehitystä ja tarjoaa iloa ja elämyksiä. 
                                Oppitunnin pituus on 45 minuuttia ja tunnilla ollaan ilman omaa aikuista.</p>
                          </div>
                         
                      </div>

                      <div className="muskari-card">
                          <img src={viisi} width="90%" alt="kum muskari 5-6 vuotiaiden soitinryhmät"></img>
                          <div className='muskari-card-text'>
                            <h3>5–6-vuotiaiden soitinryhmät</h3>
                            <p>Soitinryhmissä tutustutaan soittamiseen leikinomaisesti laulua säestäen ja melodioita etsien. Soittimina ovat mm. 5-kielinen kantele, ukulele, ksylofoni tai djembe-rumpu. 
                              Lapsi oppii uusia taitoja ja saa onnistumisen elämyksiä musiikista nauttien.</p>
                          </div>
                      </div>

                      <div className="muskari-card">          
                          <img src={musiikkivalmennus} width='100%' alt="kum musiikkivalmennus 7-8 vuotiaat"></img>
                          <div className='muskari-card-text'>
                            <h3>7–8-vuotiaiden musiikkivalmennus</h3>
                            <p>Musiikkivalmennusryhmissä leikitään, lauletaan ja soitetaan erilaisilla soittimilla (esim. lasten omat instrumentit, kanteleet ja rytmisoittimet). Lisäksi tutustutaan hieman musiikin historiaan.
                               Opetus tukee soittoharrastusta ja sen aloitusta. Oppitunnin pituus on 60 min.</p>
                          </div>
                      </div>
                      
                      <div className="muskari-card">   
                          <img src={lauluvalmennus} alt="kum lauluvalmennus muskarit" width="90%"></img>
                          <div className='muskari-card-text'>
                            <h3>3–9-vuotiaiden lauluvalmennus</h3>
                            <p>Lauluvalmennus on lasten alkeislaulunopetusta Suzuki-menetelmällä n. 3–9-vuotiaille lapsille. Tunneilla rohkaistaan oman äänen löytämiseen ja käyttämiseen monipuolisesti erilaisten laulujen ja leikin kautta.
                               Opetus tapahtuu pareittain ja jokainen oppilas saa opetusta yhteensä 30 minuuttia viikossa, josta 15 min. on yksilöopetusta ja 15 min. pariopetusta.</p>
                          </div>
                         
                      </div>
                     


                    

                    </div>
                    
          
        

          <br></br>
        
          <div style={{"textAlign": "center", padding: "20px"}}>
          <h3>Opetuspisteemme</h3>
          <br></br>
          <p style={{ fontSize: "1.1rem", marginBottom: "4px"}}>Järvenpään ryhmien opetuspisteet:</p>
             <p><strong>Musiikkiopisto</strong> (Seutulantie 8)</p>
             <p><strong>Kinnarin päiväkoti</strong> (Keskitalontie 1)</p>
             <br></br>
             <p style={{ fontSize: "1.1rem", marginBottom: "4px"}}>Tuusulan ryhmien opetuspisteet:</p>
             <p><strong>Monio</strong>&nbsp;(Patalijoonantie 6)</p>
            {/*} <p><strong>Kirjasto </strong>(Autoasemankatu 2)</p> */}
             <p><strong>Päiväkoti Venlantupa</strong> (Anttilantie 20)</p>
          </div>
          <div>
          </div>
          <div>
          <br></br>
          <p>Tässä muskariopettajiemme Outin ja Camillan videotervehdys.</p>
          <br></br>

            <video controls controlsList="nodownload" style={{ width:"100%" , borderRadius: "20px" }}>
                
                <source src={Vid} ></source>
            </video>
          </div>
          <br></br>
          
            <br></br>
         
         
          
        
        </div>
        </div>
    )
}

export default Muskari;