import React from 'react'
import vid1 from '../promoVideos/Ester.mp4'
import vid3 from '../promoVideos/Sara.mp4'

const valitys = () => {
    
    const textStyle = ({
         widht: "200px"
    })
 
    return(
        <div className="container" style={{ paddingTop: "70px"}}>
        <div id="nxs-content">
        <h2 style={{ textAlign: "center", fontWeight: "300", fontSize: "40px", marginTop: "40px", marginBottom: "24px"}}>Esiintyjävälitys</h2>  
        <p style={textStyle}>Kaipaatko juhliisi tai tilaisuuteesi elävää musiikkia? Tilaa musiikkiopistomme oppilas keikalle!</p>
        <br></br>

        <p>Oppilaamme esiintyvät sekä yksin että kokoonpanoissa, miten vain sovitaan. Muistathan kuitenkin, että suurin osa oppilaistamme on lapsia ja nuoria, ja vielä harjoittelemassa sekä instrumentinhallintaa että esiintymistä. Esitykset voivat olla esimerkiksi 2-5 kappaleen mittaisia, eikä esitysajankohta voi olla kovin myöhään illalla.</p>
        <br></br>
        <p>Pyydämme musiikkiesityksen "välityspalkkiona" lahjoitusta oppilasstipenditilillemme IBAN: FI94 5092 0920 0005 18 (BIC:OKOYFIHH). Viestikenttään kirjoitetaan: "Musiikkiopiston stipenditili, 236012". Lahjoitusten toivottu vähimmäissumma on 150€.</p>
        <br></br>
        <p>Keskisen Uudenmaan Musiikkiopisto ei vastaa esityksiin mahdollisesti liittyvistä tekijänoikeuskustannuksista.</p>

        <p>Täytä esiintyjätilauslomake <a href="https://link.webropolsurveys.com/S/46462BF00E2C8895" target="blank" rel="noopener noreferrer">TÄSTÄ</a>, niin otamme teihin yhteyttä.</p>
        <br></br>
        <p style={{ fontSize: "22px", marginBottom: "30px"}}><strong>Kiitos!</strong></p>
       </div> 
       <div className="esiintyja-social">
				<a href="https://www.facebook.com/KeskisenUudenmaanMusiikkiopisto" ><i class="fa fa-facebook fa-3x"></i></a>
        <a href="https://www.instagram.com/musiikkiopisto_kum/"><i class="fa fa-instagram fa-3x"></i></a>
        <a href="https://www.youtube.com/channel/UC2y2uuOWJqPspM7zHAK9Bvw"><i class="fa fa-youtube fa-3x"></i></a>

			</div>
       <div className="esiintyjavalitys" style={{ marginBottom: "20px"}}>
        <video  style={{height: "270px"}} controls controlsList="nodownload" >
         <source src={vid1} ></source>
        </video>
        {/*
        <video  controls controlsList="nodownload">
          <source src={vid2}></source>
        </video>
        */}
        <video controls controlsList="nodownload">
          <source src={vid3}></source>
        </video>
       </div>
       
       </div>
    )
}

export default valitys;