import React from 'react';
import './hinnasto2.css';
import NavHinnasto from './hinnasto2';


const perusHinnasto = () => {




    return(
        <div className="perus-opetus">
            <NavHinnasto />
            <div className="container">
                <div >
                
                    <div className="title-total">   
                        <h2>Perusopetuksen hinnasto</h2>
                        <br></br>
                    
                        <p>Taiteen perusopetuksen lukukausimaksu on kokonaismaksu, joka sisältää soittotunnin lisäksi erilaisia ryhmäopintoja, kuten muhaa (musiikin hahmotusaineita) ja yhteismusisointia (orkesteri, kuoro, bändi, muskari jne.). Taiteen perusopetuksessa oleva oppilas pääsee maksutta mukaan musiikkileikkikouluun. Lauluvalmennus ja koulumusikanttiopetus eivät kuulu taiteen perusopetuksen lukukausimaksuun.</p>
                        <br></br>
                        <p>Soittotunnin kesto on yleensä 45 minuuttia. 30 minuutin tunti on tarkoitettu nuorimmille oppilaille opintojen alussa. 60 minuutin soittotuntiin siirrytään syventävissä opinnoissa ja opettajan harkinnan mukaan, kun oppilas on jo pitkällä opinnoissaan.</p>

                        <br></br>
                        <i class='fas fa-tags' style={{fontsize:'24px'}}></i>

                        <table className="card-hinnasto">
                        <tbody>
                        <tr className=""><td>Lukukausimaksu, 30 min</td>
                        <td><strong> 305 €</strong></td></tr>
                        <tr className=""><td>Lukukausimaksu, 45 min</td>
                        <td><strong> 333 €</strong></td></tr>
                        <tr className=""><td>Lukukausimaksu, 60 min</td>
                        <td><strong>345 €</strong></td></tr>
                        <tr className=""><td>Kirjaamismaksu, uudet oppilaat</td>
                        <td><strong>15 €</strong></td></tr>
                        <tr className=""><td>Soitinvuokra</td>
                        <td><strong>50 € / </strong>lukukausi</td></tr>
                        <tr className=""><td>Materiaalimaksu</td>
                        <td><strong>2.50 €</strong> / lukukausi</td></tr>
                        <tr className=""><td>Sivuaine, 30 min</td>
                        <td><strong>161,70 €</strong></td></tr>
                        <tr className=""><td>Sivuaine, 45 min</td>
                        <td><strong>323,40 €</strong></td></tr>
                        </tbody>
                        </table> 
                    </div>
            </div>
            <h2>SISARUSALENNUS</h2>
                        <p>Sisarusalennus on -50% kolmannesta sisaruksesta alkaen (musiikkileikkikoulun, musiikkivalmennuksen ja taiteen perusopetuksen oppilaat). Sisarusalennusta ei myönnetä avoimen puolen opetukseen eikä koulumusikanttiopetukseen.</p>
                        <br></br>
                        <h2>MAKSUHUOJENNUKSET</h2>
                        <p>Maksuhuojennusta voidaan myöntää taiteen perusopetuksen oppilaalle, kun perheen maksukyky on heikentynyt tai jos perhe on toimeentulotuen varassa. Maksuhuojennus koskee taiteen perusopetuksen pääaineen lukukausimaksua. </p>
                       
                        <br></br>
                        <p>Maksuhuojennusta haetaan syyslukukauden alussa Eepoksessa, ja hakuaika ilmoitetaan oppilastiedotteessa. Hakemukseen liitetään todistukset perheen kuukausituloista sekä edellisen vuoden verotuksesta. Maksuhuojennuksia ei voida myöntää kesken lukukauden eikä avoimeen tai koulumusikanttiopetukseen.</p>

            </div>
                <br></br>
                <div className="muut-infot container" >
                    <div className="opintojen-lopettaminen">
                            <h2>OPINTOJEN LOPETTAMINEN</h2>
                            <p>Täysi-ikäinen oppilas/huoltaja ilmoittaa opintojen lopettamisesta sähköpostitse musiikkiopiston toimistoon musiikkiopisto@jarvenpaa.fi.</p>
                            <p>Opinnot katsotaan loppuneeksi sähköpostin lähetyspäivänä.</p>
                            <br></br>
                            <p><strong>Jos oppilas lopettaa opinnot...</strong></p>
                            <ul className="opintojen-ul">
                                <li>viimeistään kahden opintokerran jälkeen, peritään häneltä voimassa olevan hinnaston mukainen paikanvarausmaksu.</li>
                                <li>Kolmen opintokerran jälkeen lopetuksesta peritään 50 % lukukausimaksusta.</li>
                                <li>Neljännestä opintokerrasta alkaen veloitetaan koko lukukausimaksu. Huomioithan, että myös oppilaan poissaolokerta on pidetty opetuskerta.</li>
                            </ul>
                            </div>
                            <div className="opintojen-keskeyttäminen">
                            <h2>OPINTOJEN KESKEYTTÄMINEN</h2>
                            <p>Opintojen keskeytys koskee vain perusopetuksen oppilaita.</p>
                            <br></br>
                            <p> Rehtori voi myöntää opintojen keskeytyksen painavista syistä, joita ovat esim. äitiysloma, armeija, opiskelu ulkomailla tai sairaus, josta on lääkärin todistus.</p> 
                            <p>Opintojen keskeytystä ei myönnetä motivaatiosyistä tai koulu- ja harrastuskiireiden tähden.</p> 
                            <br></br>
                            <p>Anomus opintojen keskeytyksestä lähetetään<strong> sähköpostitse musiikkiopiston toimistoon musiikkiopisto@jarvenpaa.fi</strong>.</p>
                            <br></br>
                            <p><strong>Sairastuminen</strong></p>
                            <p>Poissaolot sairastumisen vuoksi voidaan hyvittää lääkärintodistusta vastaan. Omavastuu sairasloman ajalta on yksi kuukausi. Laskua ei hyvitetä sen eräpäivän jälkeen.</p>
                    </div>

        
            </div>
        
        </div>

    )
}

export default perusHinnasto;