import React from 'react';
import './oppilaalle.css'
import './tietoaOpiskellusta.css'
import Helemet from 'react-helmet';
import kuvitusKuva from '../images/kuvituskuva.jpg'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Mousewheel, Pagination } from "swiper";

const Oppilaalle = () => {

    /*
    const scrollLink = (name) => {
        const element = document.getElementById(name).scrollIntoView({ behavior: 'smooth'})
    }
    const Laskutus = () => {
        window.location.href = "/maksuohjeet"
    }
    */
    const Konserti = () => {
        window.location.href = "/konsertit"
    }



    return(
        <div>
            <Helemet>
                <title>KUM Oppilaalle</title>
                <meta name="description" content='Kum tässä kootusti infoa musiikkiopiston oppilaille. '></meta>
                <meta name="keywords" content='KUM, Oppilaalle, Opiskelu Keskisen Uudenmaan Musiikkiopisto' ></meta>
            </Helemet>
            {/*
            <div className='anchor-links'>
                <button onClick={() => scrollLink("first")}>Oppilaan opas</button>
                <button onClick={() => scrollLink("second")}>Lukukausi</button>
                <button onClick={() => scrollLink("three")}>Muhaopinnot</button>
                <button onClick={() => scrollLink("four")}>Yhteismusisointi</button>
                <button onClick={() => scrollLink("five")}>Esiintymiset</button>
                <button onClick={() => Laskutus()}>Laskutuskäytännöt</button>



            </div>
            */}
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        direction={"vertical"}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Mousewheel, Pagination]}
                        className="mySwiper"
                    >
                    <SwiperSlide>
                    <div id='' className='slide-tree'>    
                    <div className='slide-flex'>
                            <br></br>
                            <div className="title container">
                                <br></br>
                               
                                <h2>Oppilaan opas</h2>
                                
                                <p>Lukuvuosittain päivitettävään oppilaan oppaaseen on kerätty kattavasti tietoa lukukaudesta, 
                                    musiikkiopistossa opiskelemisesta, muha-opinnoista, yhteismusisoinnista, kotiharjoittelusta ja laskutuskäytännöistä. 
                                <a href='https://drive.google.com/file/d/1YEpA_dgFmey4H9uIrG16vAPpO6I-J-nH/view?usp=drive_link'
                                    rel="noreferrer" target="_blank"> Lataa tästä oppilaan opas.</a></p>


                            <br></br>
                            <p><strong>Eepos-oppilashallinta</strong></p>
                            <p>
                            Käytämme Eeposta (kum.eepos.fi) sähköisenä opinto- kirjana, ilmoittautumiseen sekä tiedotukseen 
                            <a target="_blank"  rel="noreferrer" href='https://kum.eepos.fi'> eepos.</a>  HUOM! Huolehdittehan, että sähköpostiosoite ja
                            puhelinnumero ovat Eepoksessa aina ajan tasalla. Oppilaalle ja huoltajalle on saatavilla erilliset tunnuksettoimistosta ja Eepos- sovellus on ladattavissa älylaitteelle.
                            </p>
                            <p><strong>HUOM! Huolehdittehan, että sähköpostiosoite ja puhelinnumero ovat Eepoksessa aina ajantasalla!</strong> </p>
                                </div>
                           
                           
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div id='' className='slide-four'>  
                        <div className='slide-four-img'>
                            <img alt="Keskisen Uudenmaan Musiikkiopisto" style={{ width: "100%", height: "100%"}} src={kuvitusKuva}></img>
                        </div>
                        <div className='slide-flex-center '>
                               
                                <div className="title container" style={{textAlign: "left" }}>
                                    <h2>Lukukauden päivämääriä</h2>
                                        <p>Opetus alkaa 12.8.2024</p>
                                        <p>Syysloma: 14-20.10.2024</p>
                                        <p>Itsenäisyyspäivä ke 6.12.2024</p>
                                        <p>Joululoma: 17.12.2023-6.1.2025</p>
                                        <p>Pääsiäisloma: 18.4-21.4.2025</p>
                                        <p>Vappu to 1.5.2025</p>
                                        <p>Helatorstai: 29.5.2025</p>
                                        <p>Lukuvuosi päättyy 23.5.2025</p>
                                    

                                    <br></br>
                                    <p>Soiva viikko ja muskarin tapahtumaviikko järjestetään viikoilla 6-12.11.2023 ja 8-14.4.2024.</p>
                                    <p style={{ padding: "3px"}}></p>
                                    <p>Lukukausilasku lähetetään kahdesti vuodessa, syys-lokakuussa ja tammi-helmikuussa.
                                    <a style={{ fontSize: "16px"}} href='/maksuohjeet'
                                        rel="noreferrer" target="_blank"> Lue täältä lisää laskutuskäytännöistämme</a></p>

                                </div>
                        </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className='slide-one'>
                        <div className='slide-flex'>
                            <div className="title container">
                                <h2>Esiintymiset</h2>
                                <p>
                                    Sekä oma esiintyminen että muiden esiintymisten seuraaminen on tärkeä osa
                                    musiikkiharrastusta ja oppimista. Konsertissa kuulee toisten soittoa/laulua
                                    ja monenlaista musiikkia ja oppii esiintymistaitoja. 
                                </p>
                                <br></br>
                                <p>
                                Jokaiselle oppilaalle pyritään
                                järjestämään mahdollisuus esiintyä vähintään kerran lukukaudessa. 
                                Vanhemmat ja lähipiiri ovat tervetulleita katsomaan, ja kaikkiin musiikkiopiston konsertteihin on vapaa pääsy
                                </p>
                                <button onClick={() => Konserti()} className='btn'>Konsertit</button>
                            </div>
                           
                           
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div id='first' className='slide-two'>    
                    <div className='slide-flex'>
                            <div className="title container">
                                <h2>Harjoittelusta</h2>
                                <p>
                                    Musiikkiharrastukseen kuuluu tunneilla käymisen lisäksi säännölliseen koti-harjoittelu. 
                                    Vanhempien apu ja kannustus on tärkeää kotiharjoittelussa erityisesti opintojen alkuvaiheessa.

                                </p>
                                <br></br>
                                <p>
                                    Tärkeintä kotiharjoittelussa on säännöllisyys: mieluummin usein ja vähän kerrallaan kuin silloin tällöin pitkä harjoittelurupeama. Jos kotona harjoitteleminen on jäänyt, on sen tärkeämpää tulla tunnille, jossa soitetaan ja opettaja antaa harjoitteluohjeita.

                                </p>
                            </div>
                           
                           
                        </div>
                    </div>
                    </SwiperSlide>
                   
                   
                </Swiper>
                {/*  
                <div className='low-title'>
                <div className='container'>
                    <h2>Tässä kootusti infoa musiikkiopiston oppilaille. </h2>
                </div>
                </div>
                */}
            

             
        </div>
    )
}


export default Oppilaalle;