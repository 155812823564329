import React, { useEffect, useState } from 'react'
import './blog.css';
//import { getBlogs } from '../services/blogit'
//import k from '../images/elvis.jpg'
import Axios from 'axios';
//import Zoom from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';


//import { css } from "@emotion/core";
import { jsx } from "@emotion/react";

import ClipLoader from "react-spinners/ClipLoader";

const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: purple;
  padding-top: 30px
  right: 200px
  color: purple;
  
`;

//const Url = "http://localhost:1337/blogs";
//const Url2 = "http://52.208.227.115/blogs";
const httpsBlogs = "https://kumstrapi.xyz/blogs"


const Blog =  (props) => {
    const [ blogs, setblogs ] = useState([])
    //const [ showModal, setShowModal ] = useState(true)
    const [ topFive, setTopFive ] = useState(false)
    const [ fiveBlogs, setFiveBlogs ] = useState([])
    const [ blogId, setBlogId ] = useState(null)
    const [ errMess, setErrMessage ] = useState('')
 
    useEffect(() => {
        (async() => {
            try {
                const re = await Axios.get(httpsBlogs)
                setblogs(re.data.sort((a, b) => b.id - a.id))             
                setBlogId(props.match.params.id) 
                setTopFive(false)
                setFiveBlogs(re.data.sort((a, b) => b.id - a.id).slice(0,5))
            
            } catch (e) {
                console.log("some errors")
                setErrMessage("sorry no data find....")
            }
        })()
    },[])

    const forWardBlog = (id) => {
       //setShowModal(true)
       // setBlogId(parseInt(id)))
        setTopFive(true)
        window.location.href = `/ajankohtaiset/${id}`
        window.scrollTo(500, 0)
       // console.log(id)
       // window.location.href = `http://localhost:3000/uutisetOne/${id}`
      
    }
   
  
  
    const SmallItems = () => {
        console.log(topFive)
        if (topFive) {
            return (
                <div>
                <div  className="grid1 container">
                    {fiveBlogs.map(x => 
                     <div key={x.id} onClick={() => forWardBlog(x.Otsikko)} className="item1 photo1">
     
                         <img className="photothumb1" alt="im3" style={{ maxHeight: "300px"}} src={`https://kumstrapi.xyz/${x.blogiKuva['url']}`} />
                         {/*
                         <img className="photothumb1" alt="im3" style={{ maxHeight: "300px"}} src={`http://52.208.227.115/${x.blogiKuva['url']}`} />
                         */}
     
                         <div style={{ padding: "2px"}}>
                             <p>{x.aihe} <span>{x.paivamaara}</span></p>
                             <h3>{x.Otsikko}</h3>
                             <ReactMarkdown
                                 children={x.lyhytKuvaus}
                             />
                         </div>
                     </div>
                     )}
                 </div>
                 <div className="container" style={{ display: "flex", justifyContent: "center", padding: "20px"}}>
                    <button onClick={() => setTopFive(false)} className='dropdown_btn'>Lataa lisää ajankohtaisia</button>
                 </div>
                 </div>
     
            )
        }
        else {
        //console.log("Small items", blogs)

            return(
                <div>
                <div  className="grid1 container">
                    {blogs.map(x => 
                        <div key={x.id} onClick={() => forWardBlog(x.Otsikko)} className="item1 photo1">

                            <img className="photothumb1" alt="im3" style={{ maxHeight: "300px"}} src={`https://kumstrapi.xyz/${x.blogiKuva['url']}`} />
                        {/*
                        <img className="photothumb1" alt="im3" style={{ maxHeight: "300px"}} src={`http://52.208.227.115/${x.blogiKuva['url']}`} />
                        */}

                        <div style={{ padding: "2px"}}>
                            <p>{x.aihe} <span>{x.paivamaara}</span></p>
                            <h3>{x.Otsikko}</h3>
                            <ReactMarkdown
                                children={x.lyhytKuvaus}
                            />
                        </div>
                    </div>
                    )}
                </div>
                </div>
            
            
            )
        }      
    }
    const BigItem = () => {
        
        if (blogs.length > 0){
            const one = blogs.filter(x => x.Otsikko === blogId)
            return(
                    <div className="show-blog container">
                        <br></br>
                        
                        {one.map(x => 

                        <div key={x.id}>   
                            <h2  style={{ textAlign: "left", fontWeight: "300", fontSize: "40px", width: "100%" }}>{x.Otsikko}</h2>
                            <p>{x.aihe} <span>{x.paivamaara}</span></p>
                            <div style={{ textAlign: "center"}}>
                            {/*
                            <img style={{width:"100%", maxWidth:"500px"}} src={`http://52.208.227.115/${x.blogiKuva['url']}`} alt="blog-kuva"></img>
                            */}
                                <img style={{width:"100%", maxWidth:"500px"}} src={`https://kumstrapi.xyz/${x.blogiKuva['url']}`} alt="blog-kuva"></img>


                            </div>
                            <div className="blog-text1" >
                                {/* <h3>{x.Otsikko}</h3> */ }
                                <br></br>
                            
                                <ReactMarkdown children={x.kappaleYksi} />
                            
                                {/*
                                <p>{x.kappaleKaksi}</p>

                                <p>{x.kappaleKolme}</p>
                            
                                <p>{x.kappaleNeljä}</p>
                                <p style={{marginBottom: "100px"}}>{x.kappaleKaksi}</p>
                                */}
                        </div>
                    </div>
                    )}
                </div>
                )
                
            } else {
                return(
                    <div style={{ height: "100vh" , textAlign: "center", marginTop: "10%"}}>
                        <ClipLoader
                        css={override}
                        size={150}
                        color={"#123abc"}                 
                        />
                        <div className='container' style={{ textAlgim: "center"}}>
                            <h3 style={{color: "red"}}>{errMess}</h3>
                        </div>
                    </div>
                )

            }
    }

    return(
        <div>
         <Helmet>
          <title>Ajankohtaiset</title>
          <meta name="description" content="Kum -Kesksien Uudenmaan Musiikkiopiston tapahtumat, uutiset, ilmoitukset ja blogit" />
          <link rel="canonical" href="http://kum.fi/ajankohtaiset/firstpage" />
         </Helmet>
         <br></br>
         <br></br>
         <br></br>
        
         <BigItem />
         <h3 className="container" style={{fontSize:"33px", fontWeight:"200", marginBottom: "30px", textAlign: "left", marginTop: "20px"}}>Täältä löydät musiikkiopiston ajankohtaiset tiedotteet<span><hr></hr></span></h3>
         
         <div className='container' style={{ marginBottom: "14px" }}>
            <div className='dropdown_kirjeet'>
            
                <button className='dropdown_btn' style={{ height: "60px", width:"100%"}} >Lue täältä uusin Tempo di KUM -uutiskirje </button>
                    <div className='dropdown_content'>
                        <a href='https://drive.google.com/file/d/1fCnTNErYJnMoYXVPhUB0q_oL8rJUVF9Q/view' target="blank">KUM uutiskirje 1_2024</a>
                        <a href='https://drive.google.com/file/d/1Rn6HAn_4Mm2AAczvSY40zi0ZYAHfzZ2Y/view?pli=1' target="blank">KUM uutiskirje 2_2023</a>
                        <a href="https://drive.google.com/file/d/1y4rchcjBPa1rbKIQLRHaIt6qAOVCEGjW/view" target="blank" >KUM uutiskirje 1_2023</a>
                        <a href="https://drive.google.com/file/d/11bO0gieIodFZ-KqMADMUnE4LgZSmaI1c/view?usp=sharing" target="blank" >KUM uutiskirje 3_2022</a>
                        <a href="https://drive.google.com/file/d/1v_AJtV9S_xFNcKTPBeBKZmxQq_CJ3UGJ/view?usp=sharing" target="blank" >KUM uutiskirje 2_2022</a>
                        <a href="https://drive.google.com/file/d/1W4CsBswaoKXzqmBUFStobMoTS23_Usz3/view?usp=sharing" target="blank" >KUM uutiskirje 1_2022</a>
                        <a href="https://drive.google.com/file/d/1AnQiZO0moWNTx296aaQa-txOo9K1GAdJ/view?usp=sharing" target="blank" >KUM uutiskirje 2_2021</a>
                        <a href="https://drive.google.com/file/d/10bMKLRIYuP28e7ntyChWfQww9X-_U2p_/view?usp=sharing" target="blank" >KUM uutiskirje 1_2021</a>
                        <a href="https://drive.google.com/file/d/1_yi6sL_MdSO6av4O2CAifo7kTi-eFJPZ/view?usp=sharing" target="blank" >KUM uutiskirje 1_2020</a>
                    </div>

            </div>
         </div> 
         
         <SmallItems />
         
         
        </div>
    )
}

export default Blog
