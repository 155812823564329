import React from 'react';
import './saavutettavuus.css';
import Helmet from 'react-helmet';


const Saavutettavuus = () => {



    return(
        <div className="saavutettavuus container">
          <Helmet>
              <title>Saavutettavuusseloste</title>
              <meta name="description" content="Keskisen Uudenmann Musiikkiopiston saavutettavuusseloste" />
          </Helmet>
          <h2>Saavutettavuus</h2>
          <br></br>
         
            <p>Keskisen Uudenmaan Musiikkiopiston sivustoa koskee EU:n saavutettavuusdirektiivi, jonka tavoitteena on ihmisten yhdenvertaisuus digitaalisessa yhteiskunnassa ja yhdenmukaiset vaatimukset saavutettavuudelle.</p>
            <br></br>
            <p>Kum.fi-sivusto on julkaistu 1.8.2020. Verkkosivujen kehittämisessä on huomioitu direktiivin vaatimukset ja työtä jatketaan edelleen. Selosteen tiedot perustuvat itsearvioon.</p>         
            <br></br>
            <p>Keskisen Uudenmaan Musiikkiopisto on sitoutunut noudattamaan saavutettavuusstandardeja. Varmistamme saavutettavuuden seuraavilla toimenpiteillä:</p>
           
            <div className="s-lista">
              <ul>
                <li>saavutettavuus kuuluu toiminta-ajatukseemme.</li>
                <li>saavutettavuus on otettu huomioon Keskisen Uudenmaan Musiikkiopiston käytännöissä.</li>
              </ul>
            </div>

            <p>Keskisen Uudenmaan Musiikkiopiston tavoitteena ovat teknisesti saavutettavat, sisällöllisesti ymmärrettävät ja käyttöliittymältään selkeät ja helppokäyttöiset verkkosivut kaikenlaisille käyttäjille.</p>
            <br></br>
            <p>Sivusto vastaa saavutettavuusvaatimuksia suurelta osin. Verkkosivustolla on parannettavaa seuraavissa asioissa:</p>
            
            <div className="s-lista">
              <ul>
                <li>kuvatekstit</li>
                <li>videoiden tekstitys</li>
                <li>linkkientekstit</li>
              </ul>            
            </div>

            <p>Jos huomaat puutteita sivuston saavutettavuuteen liittyvissä asioissa, voit laittaa palautetta sähköpostitse osoitteeseen: <a href="mailto:musiikkiopisto@jarvenpaa.fi">musiikkiopisto@jarvenpaa.fi</a></p>
            <br></br>
            <br></br>       
        </div>
    )
}

export default Saavutettavuus;