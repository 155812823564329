import React, { useEffect, useState } from 'react'
import './tietoaOpiskellusta.css'
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import axios from 'axios';
import Aikuisten from '../images/aikuiset.jpg'
const httpsKurssit = "https://kumstrapi.xyz/kurssits"


const Viikonloppu = () => {
    const [ viikonLoppu , setViikonLoppu ] = useState(null)


    useEffect(() => {
        async function get(){
          try {
            const res = await axios.get(httpsKurssit);
            //console.log(res.data)
            setViikonLoppu(res.data)
          } catch(e){
            console.log("no data")
          }
        }
  
        get()
    },[])
    const ShowText = () => {

        if (viikonLoppu === null){
          return(
            <div style={{ height: "80vh"}}>
              <p>Kursseja ladataan</p>
            </div>
          )
        } else {
          //console.log(viikonLoppu[0].info)
          return(
            <div className='ownMBack'>
            <div  className="ownMarkdown">
             
                <ReactMarkdown children={viikonLoppu[0].info} />
              
            </div>
            </div>
          )
        }
      }



    return(
        <div>
            <Helmet>
              <title>KUM -viikoloppu kurssit</title>
              <meta name="description" content='KUM viikonloppu kurssit'></meta>
              <meta name="keywords" content='KUM kurssit viikonloppu musiikkiopisto Keskisen Uudeanmaan'></meta>
            </Helmet>
            <div className='aikuiset-img container'>
              <img alt="KUM viikonolppu kurssit" src={Aikuisten}></img>
            </div>
            <div className='padding-image'></div>
            <div className='container'>

                <div className='opiskelu-1'>
                    <h2 className='title-h2' >Aikuisten Viikonloppukurssit</h2>

                    <div className='opiskelu-1-text'>
                        <p>
                            Viikonloppukurssit ovat kaikenikäisille aikuisille suunnattuja kursseja, jotka kestävät yhden viikonlopun. Kursseille voivat tulla sekä aloittelijat että kokeneemmat soittajat lähialueelta tai kauempaa.
                        </p>

                        <p> 
                            Viikonloppukursseja vetävät alansa ammattilaiset ja niillä tutustutaan rennolla otteella ja käytännönläheisesti kyseessä olevaan musiikkilajiin. 
                        </p>
                        <p>Järjestämme sekä 8 tunnin (8 x 45 min) että 4 tunnin (4 x 45 min) kursseja.</p>
                        
                        <ul style={{ padding: "30px"}}>
                            <li>8 tunnin kurssit maksavat 80 € / oppilas</li>
                            <li>4 tunnin kurssit maksavat 40 € / oppilas</li>                                  
                        </ul>

                        <p>Kurssit pidetään Keskisen Uudenmaan Musiikkiopiston Järvenpään toimipisteessä osoitteessa Seutulantie 8 tai Järvenpää-talon kellarikerroksessa os. Hallintokatu 4.</p>
                        <button className="btn" style={{ backgroundColor: "black" ,color: "white"}} onClick={() => window.open("https://kum.eepos.fi/#/forms", 'blank')}>Ilmoittaudu mukaan</button>

                    </div>
                </div>

                <br></br>
             
                    <ShowText />

                <br></br>
            </div>
        </div>
    )
}



export default Viikonloppu;