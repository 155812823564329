import React from 'react';
import './hinnasto2.css';
import NavHinnasto from './hinnasto2';



const muskariHinasto = () => {


    return(
        <div style={{height: "auto"}} className="muskari-opetus">
            <NavHinnasto />
            <div className="container">
        
                <div className="title-total">   
                    <h2>MUSKARI</h2>
                    <br></br>
                
                    <p style={{ fontSize: "15px"}}><strong>Tervetuloa kokeilemaan ensimmäinen kerta maksutta!</strong></p>
                    <br></br>
                    <p style={{fontSize: "15px"}}><strong>Hinnat sis. paikanvarausmaksun</strong> </p>
                    <br></br>
                    <i class='fas fa-tags' style={{fontsize:'24px'}}></i>
            
                    <table className="card-hinnasto">
                    <tbody>
                    <tr className=""><td>Musiikkileikkikoulu, 45 min</td>
                    <td><strong>119€ </strong>/ lukukausi </td></tr>
                    <tr className=""><td>Musiikkileikkikoulu/musiikkivalmennus, 60 min</td>
                    <td><strong>138€</strong>/ lukukausi</td></tr>
                    <tr className=""><td>Laulu- ja soitinvalmennus</td>
                    <td><strong>193€</strong>/ lukukausi</td></tr>
                    <tr className=""><td>Paikanvarausmaksu</td>
                    <td><strong>15€</strong></td></tr>
                    <tr className=""><td>Sisaralennus</td>
                    <td><strong>50%</strong> kolmannesta sisaruksesta alkaen</td></tr>
                    </tbody>
                    </table> 
                    <br></br>
                    <p style={{ fontSize: "15px"}}>Lukukausimaksu laskutetaan postiosoitteeseen tai verkkopankkiin tulevalla laskulla <strong>kerran lukukaudessa.</strong>  Syyslukukauden laskut lähtevät musiikkiopistolta syys-lokakuussa ja kevätlukukauden laskut tammi-helmikuussa.</p>
                <div className="" >
                <div className="">
                    <h2>OPINTOJEN LOPETTAMINEN</h2>
                    <br></br>
                    <p style={{ fontSize: "15px"}}><strong>Kaikki peruutukset tulee tehdä kirjallisesti osoitteeseen musiikkiopisto@jarvenpaa.fi.</strong></p>
                    <p style={{ fontSize: "15px"}}>Opettajalle ilmoittaminen ei riitä lopetusilmoitukseksi.</p>
                    <br></br>
                    <p style={{ fontSize: "15px"}}><strong>Mikäli lopettamisilmoitus tehdään kesken lukukauden, määräytyy lukukausimaksu seuraavasti:</strong></p>
                        <ul style={{ marginLeft: "20px"}}>
                        <li>Ensimmäinen tutustumiskerta on maksuton.</li>
                        <li>Jos oppilas lopettaa viimeistään kahden opintokerran jälkeen, peritään häneltä voimassa olevan hinnaston mukainen paikanvarausmaksu (15 €).</li>
                        <li>Kolmannen opintokerran jälkeen lopettavalta peritään 50% lukukausimaksusta.</li>
                        <li>Neljännestä opintokerrasta alkaen veloitetaan koko lukukausimaksu.</li>
                        
                        </ul>
                        <br></br>
                        <p><strong>Huomioithan, että myös oppilaan oma poissaolokerta on pidetty opetuskerta.</strong></p>

                </div>
                </div>
            </div>
            
            {/* UKK Linkki täältä löydat cvastaukset */}
        
            </div>
      </div>
    )
}

export default muskariHinasto;