import React from 'react';
import './joulu.css'
import Konsertti from '../promoVideos/jhelmi.jpg'
import Typewriter from 'typewriter-effect';




const Joulu = () => {

    const Konsertit = () => {
      window.location.href = "/konsertit"
    }


    return(
        <div className="main-konsertti">
           
           {/*
           <div className="joulu-texti">
              <h3>KUM -YouTube</h3>
           </div>
           */}
           <div className='konsertti-box'>
           <div className='konsertti-image'>
              <img src={Konsertti}  ></img> 
            
           </div>

           <div className='konsertti-text'>
                <div className='konsertti-words'>

                   {/*<h2 style={{fontWeight: "300", textDecoration:"none", fontSize: "50px"}}>Konsertit</h2> */}
                   <Typewriter
                     options={{
                        strings: ["Katso tulevat konsertit täältä"],
                        autoStart: true,
                        loop: true
                     }}
                   />
                   
                   <button  className="btn" onClick={Konsertit} ><span>Konsertit</span></button>

                </div>
                

           </div>
           </div>
        
        </div>
    )
}

export default Joulu;