

import './info.css'




const Info = () => {



    return(
        <div className="container info">
            <div>
                <div className=''>
                    <h2>KESKISEN UUDENMAAN MUSIIKKIOPISTO</h2>
                </div>
                <div className='info-text'>
                    <p>Keskisen Uudenmaan Musiikkiopisto eli KUM on Järvenpään kaupungin ja Tuusulan kunnan yhteinen kunnallinen musiikkiopisto,joka tarjoaa klassisen sekä pop/jazz-musiikin opetusta. VOS-oppilaitoksena KUM tarjoaa taiteenperusopetusta sekä avoimen puolen musiikinopetusta.</p>
                    <br></br>
                    <p>KUM on perustettu vuonna 1972 ja on alusta asti toiminut Järvenpään kaupungin sekä Tuusulan kunnan yhteisenä musiikkiopistona. 50-vuotisjuhlavuotta vietettiin vuonna 2022, jolloin opistossa on yhteensä n. 1100 oppilasta ja 50 opettajaa.</p>
                    <br></br>
                    <p>Musiikkiopiston toimintaa järjestetään eri toimipisteissä. Järvenpään ja Tuusulan omien toimipisteiden lisäksi opetusta järjestetään Järvenpää-talolla, kouluilla ja päiväkodeilla. Vuonna 2023 opiston Tuusulan toimipiste muuttaa Hyrylään valmistuvaan lukio- ja kulttuurikeskus Monioon.</p>
                </div>
                <br></br>
            </div>
            <div>
                <div className=''>
                    <h2>ORGANISAATIO</h2>
                </div>
                <div className='info-text'>
                    <p>Keskisen Uudenmaan Musiikkiopisto kuuluu hallinnollisesti Järvenpään kaupungin organisaatioon. Ylin päättävä elin on johtokunta, jonka jäsenistö koostuu sekä Järvenpään että Tuusulan luottamushenkilöistä. Opiston operatiivista toimintaa johtaa opiston rehtori apunaan apulaisrehtori sekä opintosihteeri.</p>
                    <br></br>
                    <p>Opettajakunta jakautuu tiimeihin opetusaineensa perusteella, ja tiimien vetäjät muodostavat yhdessä hallinnon henkilökunnan kanssa johtotiimin, jonka tehtävänä on suunnitella ja ohjata musiikkiopiston toimintaa.</p>
                    <br></br>
                    <h3>Hallinto:</h3>
                    <div className='info-list'>
                        <p>Rehtori: Sauli Tyni</p>
                        <p>Apulaisrehtori: Juha-Pekka Putkonen</p>
                        <p>Opintosihteeri: Taina Perttilä</p>
                      
                    </div>

                    <br></br>
                    <h3>Tiimit:</h3>
                    <div className='info-list'>
                        <p>Pianotiimi: Paavo Maijala</p>
                        <p>Jousitiimi: Tuire Arpalahti</p>
                        <p>Puhallintiimi: Mari Uusitalo</p>
                        <p>Pop/jazz-tiimi: Pasi Könönen</p>
                        <p>Muut harmoniasoittimet ja laulu -tiimi: Satu Kaarisola-Kulo</p>
                        <p>Muha-tiimi: Marja Jukkola</p>
                        <p>Muskaritiimi: Camilla Lehtonen</p>
                    </div>
                    <br></br>
                    <a href='/opettajat'>Katso tästä kaikki musiikkiopiston opettajat</a>

                </div>
                <br></br>
            </div>
        
            <div>
                <div className=''>
                    <h2>MUSIIKKIOPISTON JOHTOKUNTA</h2>                  
                </div>
                <p className='johtokunta'>Johtokunnan jäsenet ja varajäsenet (suluissa) 2021-2025</p>
                <div className='info-text'>
                    <br></br>
                    <h3>Järvenpää:</h3>
                    <div className='info-list'>
                            <p>Tuija Kuusisto pj. (Hanna Partanen)</p>
                            <p>Kari Haapanen (Alice Utriainen)</p>
                            <p>Pasi Koskinen (Antti Honkanen)</p>
                    </div>
                    <br></br>
                    <h3>Tuusula:</h3>
                    <div className='info-list'>
                            <p>Keimo Joensuu (vpj.) (Leena Jäntti)</p>
                            <p>Mikaela Vuorio (Janne Hermunen)</p>
                            <p>Tarja Hakonen (Toni Luode)</p>
                    </div>
                    <br></br>
                    <div className='info-list'>
                            <p>Kaupunginhallituksen edustaja: Tarja Edry</p>
                            <p>Esittelijä ja valmistelija: rehtori</p>
                            <p>Sihteeri: opintosihteeri</p>
                    </div>

                </div>
            </div>
            

                
        </div>
        
        
    )
}


export default Info;