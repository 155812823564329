import './hinnasto2.css';
import NavHinnasto from './hinnasto2';


const HinnastoMain = () => {


    return(
        <div className='perus-opetus'>
            <NavHinnasto />
            <div className='container'>
                <div>
                    <div className='title-total'>
                        <h2>LASKUTUSKÄYTÄNNÖT</h2>
                        <br></br>
                        <p>Lukukausimaksut laskutetaan Järvenpään kaupungilta tulevalla laskulla joko postitse tai sähköisenä verkkolaskuna. Laskureskontran Järvenpään kaupungilla hoitaa Sarastia. Laskukopion voi siis pyytää tarvittaessa suoraan 
                         <a href='https://asiakaspalvelu.sarastia.fi/lomakkeet/pyyda-laskukopio/' target="_blank" rel="noreferrer"> Sarastiasta</a>   </p>
                        <br></br>
                        <p>Syyslukukauden laskut lähetetään syys-lokakuussa ja kevätlukukauden laskut tammi-helmikuussa. Lukukausimaksu laskutetaan kerralla. Laskulle voi pyytää pidennettyä maksuaikaa, katso ohjeet alla. Mikäli maksu viivästyy, eikä lykkäystä ole haettu, lähetetään maksumuistutus 14-21 vuorokauden kuluttua eräpäivästä. Mikäli saatava on ensimmäisen maksumuistutuksen jälkeen edelleen avoinna, asiakkaalle lähetetään perintäkirje. 
                            Perintätoimisto Intrum huolehtii maksumuistutusten lähettämisestä ja hoitaa perintätoimet. </p>
                        
                        <p>Katso useimmin kysytyt kysymykset <a href='/faq' target="_blank"> täältä. </a> </p>
                        <br></br>
                        <h2>MUSIIKKIOPISTON LUKUKAUSIMAKSUN MAKSAMINEN ERISSÄ</h2>
                        <br></br>
                        <p>1. Musiikkiopistolta tulee yksi lasku, joka sisältää lukukausimaksun kokonaisuudessaan.</p>
                        <p>2. Mikäli haluat pyytää muutosta maksuaikaan, laita viestiä osoitteeseen  musiikkiopisto@jarvenpaa.fi. Mainitse viestissä laskutettavan oppilaan nimi.</p>
                        <p>3. Saat vastauksena maksuaikaehdotuksen.</p>
                        <p>4. Suorita maksujen erät sovitun maksuaikataulun mukaisesti laskusi tiedoilla.</p>
                        <br></br>
                        <p><strong>Yksittäisistä eristä ei tule laskuja kotiin, joten on tärkeää säilyttää alkuperäinen lasku ja maksaa kaikki erät sen viitetiedoilla. </strong>
                        Kysy tarvittaessa lisätietoja musiikkiopiston toimistosta.
                        </p>
                        <br></br>
                        {/*
                        <h2>SISARUSALENNUS</h2>
                        <p>Sisarusalennus on -50% kolmannesta sisaruksesta alkaen (musiikkileikkikoulun, musiikkivalmennuksen ja taiteen perusopetuksen oppilaat). Sisarusalennusta ei myönnetä avoimen puolen opetukseen eikä koulumusikanttiopetukseen.</p>
                        <br></br>
                        <h2>MAKSUHUOJENNUKSET</h2>
                        <p>Maksuhuojennusta voidaan myöntää taiteen perusopetuksen oppilaalle, kun perheen maksukyky on heikentynyt tai jos perhe on toimeentulotuen varassa. Maksuhuojennus koskee taiteen perusopetuksen pääaineen lukukausimaksua. </p>
                       
                        <br></br>
                        <p>Maksuhuojennusta haetaan syyslukukauden alussa Eepoksessa, ja hakuaika ilmoitetaan oppilastiedotteessa. Hakemukseen liitetään todistukset perheen kuukausituloista sekä edellisen vuoden verotuksesta. Maksuhuojennuksia ei voida myöntää kesken lukukauden eikä avoimeen tai koulumusikanttiopetukseen.</p>

                        */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HinnastoMain;